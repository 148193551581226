import React from "react";
import { createRoot } from "react-dom/client";
import "./styles.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone, faEnvelope, faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import { faFacebook } from "@fortawesome/free-brands-svg-icons";

import service1 from "./images/services-1.jpg";
import service2 from "./images/services-2.jpg";
import service3 from "./images/services-3.jpg";

function App() {
  const scrollToSection = (id) => {
    document.getElementById(id).scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div className="app">
      {/* Navigation Menu */}
      <nav className="nav">
        <ul>
          <li onClick={() => scrollToSection("introduction")}>Omus</li>
          <li onClick={() => scrollToSection("services")}>Palvelut</li>
          <li onClick={() => scrollToSection("contact")}>Yhteystiedot</li>
        </ul>
      </nav>

      {/* Hero Section */}
      <header className="hero" id="hero">
        <div
          className="hero-image"
        />
        <div className="hero-text">
          <h1>Ompelu Omus</h1>
          <p>Ompelupalvelut</p>
        </div>
      </header>

      {/* Omus */}
      <section className="introduction" id="introduction">
        <h2>Ompelu Omus</h2>
        <p>Lappeenrannassa toimiva ompelimo Ompelu Omus on ompelija Oona Mustosen perustama yritys, joka tarjoaa ompelupalveluita ammattitaidolla ja yksilöllisellä otteella. Kilpailukykyisillä hinnoilla valmistuvat niin juhlapuvut ja vauvanvaatteet kuin muodistukset ja korjausompelutkin. Mittojenotto ja sovitukset voidaan tehdä tarpeen mukaan myös asiakkaan luona, minkä lisäksi autan mielelläni myös kangasvalinnoissa ja muissa hankinnoissa.</p>
      </section>

      {/* Palvelut */}
      <section className="services" id="services">
        <h2>Palvelut</h2>
        <div className="service-list">
          <div className="service-item">
            <img src={service1} alt="Mittatilaus" className="service-icon" />
            <h3>Mittatilausvaatteet</h3>
            <p>Toisinaan tuntuu, ettei kaupoista löydy juuri sitä mallia, kokoa tai materiaalia, jota vaatteeltaan kaipaa. Mittatilaustyönä saatkin etsimäsi aarteen – kuten hyvin istuvat housut, jakkupuvun tai haaveilemasi valmistujaismekon – henkilökohtaisesti suunniteltuna, omilla mitoillasi ja päällesi sovitettuna.</p>
          </div>
          <div className="service-item">
            <img src={service2} alt="Korjaukset" className="service-icon" />
            <h3>Korjaukset ja muutokset</h3>
            <p>Vanhaa mutta hyvää ei kannata heittää roskiin. Ikääntyneestä vaatteesta saat muodistuksella ekologisen, käyttökelpoisen ja tähän päivään sopivan pukimen. Teemme myös pienennykset, vetoketjun vaihdot sekä muut korjaukset.</p>
          </div>
          <div className="service-item">
            <img src={service3} alt="Konsultointi" className="service-icon" />
            <h3>Sisustusompelu</h3>
            <p>Teemme pienimuotoisia sisustusompelutöitä, kuten tyynyjä, päiväpeittoja ja verhoja.</p>
          </div>
        </div>
      </section>

      {/* Yhteystiedot */}
      <footer className="contact" id="contact">
        <h2>Yhteystiedot</h2>
        <ul className="contact-list">
          <li>
            <FontAwesomeIcon icon={faMapMarkerAlt} className="contact-icon" />
            <span><a href="https://www.google.fi/maps/place/Untamonkatu+25,+53100+Lappeenranta">Untamonkatu 25 53100 Lappeenranta</a></span>
          </li>
          <li>
            <FontAwesomeIcon icon={faPhone} className="contact-icon" />
            <a href="tel:+358449708545">+358 44 970 8545</a>
          </li>
          <li>
            <FontAwesomeIcon icon={faEnvelope} className="contact-icon" />
            <a href="mailto:ompeluomus@gmail.com">ompeluomus@gmail.com</a>
          </li>
          <li>
            <FontAwesomeIcon icon={faFacebook} className="contact-icon" />
            <a href="https://facebook.com/ompeluomus">ompeluomus</a>
          </li>
        </ul>
      </footer>
    </div>
  );
}

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);
root.render(<App />);
